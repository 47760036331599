import {
  BoostCredentialsEntity,
  type BoostCredentialsData,
  type BoostCredentialsResponseData
} from '@svelte/lib/entity/boost-credentials';
import { Game } from '@svelte/lib/enum/drivers/base';

export const boostCredentialsMapper = (
  data: BoostCredentialsResponseData,
  game: Game,
  serverLabel: string
): BoostCredentialsEntity => {
  const credentialsData: BoostCredentialsData = {
    isValorant: game === Game.valorant,
    username: data.username,
    password: data.password,
    summonername: data.summonername,
    server: serverLabel
  };

  return new BoostCredentialsEntity(credentialsData);
};
