import { BaseEntity } from '@svelte/lib/entity/base.entity';
import { type Enquiry, ServiceGroupEnum } from '@svelte/lib/interface/enquiry';
import type { Game } from '@svelte/lib/enum/drivers/base';

export class EnquiryEntity extends BaseEntity<Enquiry> {
  private _serviceGroup!: ServiceGroupEnum;
  private _ebGame!: Game;

  constructor(data: Enquiry) {
    super(data);

    this.setServiceGroup();
    this.setEbGame();
  }

  get game() {
    return this.data.game;
  }

  get ebGame() {
    return this._ebGame;
  }

  get serviceType() {
    return this.data.serviceType;
  }

  set serviceGroup(value: ServiceGroupEnum) {
    this._serviceGroup = value;
  }

  get serviceGroup() {
    return this._serviceGroup;
  }

  get initialLeagueDivision() {
    return this.data.initialLeagueDivision!;
  }

  get desiredLeagueDivision() {
    return this.data.desiredLeagueDivision!;
  }

  get numberOfWins() {
    return this.data.numberOfWins!;
  }

  get numberOfPlacements() {
    return this.data.numberOfPlacements!;
  }

  get initialLevel() {
    return this.data.initialLevel!;
  }

  get desiredLevel() {
    return this.data.desiredLevel!;
  }

  get numberOfGames() {
    return this.data.numberOfGames!;
  }

  get typeOfCoach() {
    return this.data.typeOfCoach!;
  }

  get numberOfHours() {
    return this.data.numberOfHours!;
  }

  get initialMasteryLevel() {
    return this.data.initialMasteryLevel!;
  }

  get desiredMasteryLevel() {
    return this.data.desiredMasteryLevel!;
  }

  get currentLp() {
    return this.data.currentLp!;
  }

  get desiredLp() {
    return this.data.desiredLp!;
  }

  get queueTypeEnum() {
    return this.data.queueTypeEnum!;
  }

  get numberOfKills() {
    return this.data.numberOfKills!;
  }

  get platform() {
    return this.data.platform!;
  }

  get badge() {
    return this.data.badge!;
  }

  get challenge() {
    return this.data.challenge!;
  }

  get currentRr() {
    return this.data.currentRr!;
  }

  get desiredRr() {
    return this.data.desiredRr!;
  }

  private setServiceGroup() {
    const splitVal = this.data.serviceType.split('_');

    switch (splitVal[1]) {
      case 'bst':
        this.serviceGroup = ServiceGroupEnum.Boosting;
        break;
      case 'cch':
        this.serviceGroup = ServiceGroupEnum.Coaching;
        break;
      case 'mkt':
        this.serviceGroup = ServiceGroupEnum.Marketplace;
        break;
      default:
        throw new Error('Unknown service group by identifier ' + splitVal[1]);
    }
  }

  private setEbGame() {
    this._ebGame = this.data.game;
  }
}
