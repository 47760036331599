import { BaseEntity } from '@svelte/lib/entity/base.entity';

export interface BoostCredentialsResponseData {
  summonername: string;
  username?: string;
  password?: string;
}

export interface BoostCredentialsData {
  isValorant: boolean;
  summonername: string;
  server: string;
  username?: string;
  password?: string;
}

export class BoostCredentialsEntity extends BaseEntity<BoostCredentialsData> {
  get isValorant() {
    return this.data.isValorant;
  }
  get summonername() {
    return this.data.summonername;
  }
  get server() {
    return this.data.server;
  }
  get username() {
    return this.data.username;
  }
  get password() {
    return this.data.password;
  }
}
