export enum SortingAvailableOffers {
  newest = '-created_at',
  oldest = 'created_at',
  priceHighToLow = '-price',
  priceLowToHigh = 'price',
  pricePublicHighToLow = '-price_public',
  pricePublicLowToHigh = 'price_public',
  boughtAtNewest = '-bought_at',
  boughtAtOldest = 'bought_at'
}

export enum MacroDetectionMethod {
  Unknown = 0,
  turnstileFrontend = 1,
  turnstileBackend = 2,
  isTrusted = 3
}
