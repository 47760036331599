import { Game, DriverServiceType } from '@svelte/lib/enum/drivers/base';

export interface Enquiry {
  serviceType: DriverServiceType;
  game: Game;
  initialLeagueDivision?: string;
  desiredLeagueDivision?: string;
  numberOfWins?: number;
  numberOfPlacements?: number;
  initialLevel?: number;
  desiredLevel?: number;
  numberOfGames?: number;
  typeOfCoach?: string;
  numberOfHours?: number;
  initialMasteryLevel?: string;
  desiredMasteryLevel?: string;
  currentLp?: number;
  desiredLp?: number;
  queueTypeEnum?: string;
  numberOfKills?: string;
  platform?: string;
  badge?: string;
  challenge?: string;
  currentRr?: number;
  desiredRr?: number;
}

export enum ServiceGroupEnum {
  Boosting = 'boosting',
  Coaching = 'coaching',
  Marketplace = 'marketplace'
}
